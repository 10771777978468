@import "../main/abstracts/index";

.search {
    .page__header & {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    // height: $header__height--mobile;

    // @include media-breakpoint-up(lg) {
    //     height: $header__height--unpinned;
    // }

    // @include media-breakpoint-up(xxl) {
    //     height: $header__height;
    //     transition: height .25s ease-in-out;
    // }

    // html.headroom--unpinned & {
    //     @include media-breakpoint-up(xxl) {
    //         height: $header__height--unpinned;
    //     }
    // }

    body[data-template*="home"]:not(.navigation-open) &{

        .search__preview{
            margin-top: 0;
        }

        /*
        html.headroom--top & {
            margin-top: 0;

            @include media-breakpoint-up(md) {
                margin-top: -1px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: -9px;
            }

            @include media-breakpoint-up(xxl) {
                margin-top: -50px;
            }
        }*/
    }

    @include media-breakpoint-down(md) {
        &--preview{
            display: none;
        }
    }
}
